import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import Home from "./components/home";
import PageProperties from "./components/page-properties";
import PageProperty from "./components/page-property";
import Contact from "./components/contact";
import PrivacyPolicy from "./components/privacy-policy";

class Root extends Component {
  render() {
    return (
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/properties" component={PageProperties} />
            <Route path="/contact" component={Contact} />
            <Route path="/property/:id" component={PageProperty} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("edenmove"));

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Property extends Component {
  state = {
    property: {},
  };

  componentWillMount() {
    const { id } = this.props;

    fetch(process.env.REACT_APP_PUBLIC_URL + "/api/property.json")
      .then((response) => response.json())
      .then((data) => this.setState({ property: data[id] }));
  }

  render() {
    let publicUrl = process.env.REACT_APP_PUBLIC_URL + "/";
    return (
      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <div className="ltn__blog-meta">
                  <ul>
                    {this.state.property?.tags &&
                      this.state.property.tags.map((tag, index) => {
                        if (index % 2 === 0) {
                          return (
                            <li
                              className="ltn__blog-category"
                              key={"property-li-" + index}
                            >
                              <Link to="#">{tag}</Link>
                            </li>
                          );
                        } else {
                          return (
                            <li
                              className="ltn__blog-category"
                              key={"property-li-" + index}
                            >
                              <Link className="bg-orange" to="#">
                                {tag}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />
                      {this.state.property.created_at}
                    </li>
                  </ul>
                </div>
                <h1>{this.state.property.name}</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  {this.state.property.address1 +
                    " " +
                    this.state.property.address2 +
                    " " +
                    this.state.property.city +
                    " " +
                    this.state.property.postcode}
                </label>
                <h4 className="title-2">Description</h4>
                <p>{this.state.property.description}</p>
                <h4 className="title-2 mb-10">Amenities</h4>
                <div className="row">
                  {this.state.property?.amenities &&
                    this.state.property.amenities.map((amenity, index) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6"
                          key={"property-div-" + index}
                        >
                          <label className="checkbox-item">
                            {amenity}
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      );
                    })}
                </div>
                <h4 className="title-2">Location</h4>
                <div className="property-details-google-map mb-60">
                  <iframe
                    src={this.state.property.data?.map}
                    width="100%"
                    height="100%"
                    frameBorder={0}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Property;

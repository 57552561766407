import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CategoryV2 extends Component {
  render() {
    let publicUrl = process.env.REACT_APP_PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  Join Our Platform
                </h6>
                <h1 className="section-title">Onboarding</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <Link to="#">
                  <span className="category-icon">
                    <i className="fas fa-handshake" />
                  </span>
                  <span className="category-number">Commission</span>
                  <span className="category-title">Grow Together, Earn Together</span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <Link to="#">
                  <span className="category-icon">
                    <i className="fas fa-check" />
                  </span>
                  <span className="category-number">Subscription</span>
                  <span className="category-title">One Fee, Limitless Possibilities</span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <Link to="#">
                  <span className="category-icon">
                  <i className="fas fa-user-tie" />
                  </span>
                  <span className="category-number">Service</span>
                  <span className="category-title">Focused Solutions, Targeted Success</span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
                <Link to="#">
                  <span className="category-icon">
                  <i className="fas fa-rocket" />
                  </span>
                  <span className="category-number">Enterprise</span>
                  <span className="category-title">Distinct Plans, Exclusive Wins</span>
                  <span className="category-btn d-none">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryV2;

import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import About from "./section-components/about";
import Video from "./section-components/video-v2";
import Features from "./section-components/features-v1";
import UpComingProduct from "./section-components/upcoming-product";
import Apartment from "./section-components/apartment";
import ProductSlider from "./section-components/product-slider-v2";
import Availability from "./section-components/availability";
import Neighbour from "./section-components/neighbour";
import Cateogory from "./section-components/category-v2";
import Testimonial from "./section-components/testimonial-v2";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToAction from "./section-components/call-to-action";
import Footer from "./global-components/footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Banner />
      <About />
      <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---" />
      <Cateogory />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Home;

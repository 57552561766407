import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Properties from "./section-components/properties";
import CallToActon from "./section-components/call-to-action";
import BlogSlider from "./blog-components/blog-slider-v1";
import Sponsor from "./section-components/sponsor-v2";
import CallToAction from "./section-components/call-to-action";
import Footer from "./global-components/footer";

const PageProperties = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Our Properties" subheader="properties" />
      <Properties />
      <Footer />
    </div>
  );
};

export default PageProperties;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Properties extends Component {
  state = {
    properties: [],
  };

  componentWillMount() {
    fetch(process.env.REACT_APP_PUBLIC_URL + "/api/property.json")
      .then((response) => response.json())
      .then((data) => this.setState({ properties: data }));
  }

  render() {
    let publicUrl = process.env.REACT_APP_PUBLIC_URL + "/";

    return (
      <div className="ltn__gallery-area mb-120">
        <div className="container">
          <div className="ltn__gallery-active row ltn__gallery-info-hide---">
            <div className="ltn__gallery-sizer col-1" />
            {this.state.properties.map((property, index) => (
              <div
                className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12"
                key={"properties-key-" + index}
              >
                <div className="ltn__gallery-item-inner">
                  <div className="ltn__gallery-item-img">
                    <a href={"/#/property/" + index}>
                      <img
                        src={publicUrl + property.images[0]}
                        alt="Image"
                      />
                      <span className="ltn__gallery-action-icon">
                        <i className="fas fa-map-marker-alt" />
                      </span>
                    </a>
                  </div>
                  <div className="ltn__gallery-item-info">
                    <h4 className="go-top">
                      <Link to={"/property/" + index}> {property.name} </Link>
                    </h4>
                    <p>{property.created_at}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Properties;

import React from "react";
import Navbar from "./global-components/navbar";
import Property from "./property-components/property";
import CallToAction from "./section-components/call-to-action";
import Footer from "./global-components/footer";
import PropertySlider from "./property-components/property-slider";
import { useParams } from "react-router-dom";

const PageProperty = () => {  
  return (
    <div>
      <Navbar />
      <PropertySlider id={useParams().id} />    
      <Property id={useParams().id} />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default PageProperty;

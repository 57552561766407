import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class PropertySlider extends Component {
  state = {
    property: [],
  };

  componentWillMount() {
    const { id } = this.props;

    fetch(process.env.REACT_APP_PUBLIC_URL + "/api/property.json")
      .then((response) => response.json())
      .then((data) => this.setState({ property: data[id] }));
  }

  render() {
    let publicUrl = process.env.REACT_APP_PUBLIC_URL + "/";

    return (
      <div className="ltn__img-slider-area mb-90">
        <div className="container-fluid">
          <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
            {this.state.property.images &&
              this.state.property.images.map((image, index) => {
                return (
                  <div className="col-lg-12" key={"property-slider-div-" + index}>
                    <div className={"ltn__img-slide-item-" + index}>
                      <a href={image} data-rel="lightcase:myCollection">
                        <img src={publicUrl + image} alt="Image" />
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default PropertySlider;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class About extends Component {
  render() {
    let publicUrl = process.env.REACT_APP_PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/home/about_us.jpg"}
                  alt="About Us"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/C7KEW28Aglc?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    Premier choice to target global Chinese market.
                  </h1>
                  <p>
                    Unveiling the future of seamless property services for the
                    global Chinese community.
                  </p>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-house-4" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="#">Dominant information channel</a>
                    </h4>
                    <p>
                      We capitalize on WeChat MiniProgram as the primary source
                      of information for the Chinese audience, ensuring our
                      services are easily accessible and highly relevant to our
                      target market.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-call-center-agent" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="#">One-stop service</a>
                    </h4>
                    <p>
                      Eden Move provides a comprehensive suite of property and
                      building-related services, tailored to meet the specific
                      needs of Chinese clientele. From property search to
                      conveyancing solicitors, we have every aspect covered.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-book" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="#">Seamless end-to-end experience</a>
                    </h4>
                    <p>
                      he Eden Move WeChat MiniProgram enables clients to
                      complete the entire buying, renting, and maintenance
                      process within a single platform. Our seamless integration
                      of services streamlines the property journey, delivering
                      an unparalleled user experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;

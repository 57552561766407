import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.REACT_APP_PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Features
                </h6>
                <h1 className="section-title">ONE STOP PROPERTY PLATFORM</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <i className="fas fa-building" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Showcase Your Elite Properties</Link>
                  </h3>
                  <p>
                    Elevate your property listings with Eden Move, the
                    distinguished platform for captivating selling or rental
                    opportunities in the global Chinese market.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <i className="fas fa-gbp" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Streamlined Buying Experience</Link>
                  </h3>
                  <p>
                    Immerse yourself in our sophisticated selling process,
                    featuring seamless scheduling and offering procedure,
                    designed to cater to the discerning clientele.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <i className="fas fa-user-tie" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Seamless Integration</Link>
                  </h3>
                  <p>
                    Effortlessly connect with esteemed brokers and solicitors,
                    ensuring a smooth buying or renting process. Benefit from
                    the convenience of online contract generation and tailored
                    to meet the specific requirements of each supplier.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <i className="fas fa-house" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="#">Exceptional Property Management</Link>
                  </h3>
                  <p>
                    Entrust your properties to the capable hands of Eden Move,
                    where we deliver meticulous care and maintenance, preserving
                    the value and allure of your esteemed investments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;
